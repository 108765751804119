import React from 'react';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import {Close} from '@mui/icons-material';
import {Dialog as MuiDialog, DialogActions, DialogContent, IconButton} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import {styled as muiStyled} from '@mui/material/styles';

const CustomDialog = muiStyled(MuiDialog, {
  shouldForwardProp: prop => prop !== 'zIndex',
})(({zIndex, theme}) => ({
  zIndex: zIndex ?? theme.zIndex.modal,
}));

export const Dialog = ({
  children,
  actionAlign = 'space-between',
  actionContent = null,
  closeable = false,
  fullScreen = false,
  handleClose,
  open = false,
  title = '',
  titleColor = COLORS.fupaSecondary,
  zIndex = undefined,
}) => {
  const showActions = actionContent ? (
    <DialogActions sx={{padding: SIZES[16], justifyContent: actionAlign}}>{actionContent}</DialogActions>
  ) : null;
  const showClose = closeable ? (
    <IconButton
      aria-label='close'
      sx={{
        position: 'absolute',
        right: 0,
        top: 0,
        color: COLORS.white,
      }}
      onClick={handleClose}
      size='large'>
      <Close />
    </IconButton>
  ) : null;

  return (
    <CustomDialog open={open} onClose={handleClose} fullScreen={fullScreen} maxWidth={'xs'} zIndex={zIndex}>
      <MuiDialogTitle
        sx={{
          backgroundColor: titleColor,
          height: SIZES[48],
          padding: SIZES[12],
        }}>
        <Typography variant='title1'>{title}</Typography>
        {showClose}
      </MuiDialogTitle>
      <DialogContent sx={{padding: 0}}>{children}</DialogContent>
      {showActions}
    </CustomDialog>
  );
};
