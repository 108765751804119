import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {SIZES, Button, Typography, COLORS} from '@fupa/fupa-uikit';
import {func, string} from 'prop-types';
import {authorizedAPI} from 'app/services/api';
import {useForm} from 'react-hook-form';
import {isAtInAddress, isFilled, isValidEmailAddress} from 'app/helpers/ValidationRules';
import {CardSubheading} from 'app/styles/heading';
import {TextField, useMediaQuery, useTheme} from '@mui/material';
import {Dialog} from 'app/components/dialog/Dialog';
import ReCAPTCHA from 'react-google-recaptcha';

const postContactMessage = (id, email, name, message, club) => {
  const data = {
    name,
    email,
    club,
    message,
  };
  return authorizedAPI.post(`/marketplace/${id}/contact`, data);
};

const FormContainer = styled.div`
  display: flex;
  padding: ${SIZES['16']};
  flex-direction: column;
`;

const StyledTypography = styled(Typography)`
  &&& {
    font-style: italic;
  }
`;

const CaptchaContainer = styled.div`
  padding-top: ${SIZES['16']};
  min-height: ${SIZES['116']};
`;

const ErrorTypography = styled(Typography)`
  &&& {
    color: ${COLORS.neonRed};
  }
`;

const MarketplaceContactForm = ({detailId, title, onContactResolve, onContactReject, handleClose, open}) => {
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      club: '',
      email: '',
      message: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onSubmit = ({name, club, email, message}) => {
    // This function will only be called, if all inputs are valid
    setDisabled(true);
    postContactMessage(detailId, email, name, message, club)
      .then(() => {
        setDisabled(false);
        onContactResolve();
      })
      .catch(() => {
        setDisabled(false);
        onContactReject();
      });
  };

  const showTitle = title ? <CardSubheading variant='subheading2'>{title}</CardSubheading> : null;

  const actionContent = <Button disabled={disabled} secondary label='Senden' onClick={handleSubmit(onSubmit)} />;

  useEffect(() => {
    register('captchaToken', {required: 'Bitte bestätige, dass du kein Roboter bist.'});
  }, []);

  const handleRecaptchaChange = token => setValue('captchaToken', token);

  return (
    <Dialog
      actionAlign='flex-end'
      actionContent={actionContent}
      closeable={true}
      fullScreen={fullScreen}
      handleClose={handleClose}
      open={open}
      title='Kontaktieren'
      zIndex={1999999999}>
      <FormContainer>
        {showTitle}
        <TextField
          label='Name'
          name='name'
          color='secondary'
          required={true}
          autoFocus={true}
          fullWidth={true}
          InputLabelProps={{required: false}}
          {...register('name', {validate: {isFilled}})}
          helperText={errors.name?.message ?? ' '}
          error={!!errors.name}
        />
        <TextField
          label={
            <>
              Verein<StyledTypography variant='caption1'> - optional</StyledTypography>
            </>
          }
          color='secondary'
          name='club'
          helperText=' '
          {...register('club')}
        />
        <TextField
          label='Email'
          type='email'
          color='secondary'
          name='email'
          required={true}
          InputLabelProps={{required: false}}
          {...register('email', {
            validate: {
              isFilled,
              isAtInAddress,
              isValidEmailAddress,
            },
          })}
          helperText={errors.email?.message ?? ' '}
          error={!!errors.email}
        />
        <TextField
          id='standard-textarea'
          label='Nachricht'
          name='message'
          color='secondary'
          multiline
          required={true}
          InputLabelProps={{required: false}}
          {...register('message', {
            validate: isFilled,
          })}
          error={!!errors.message}
          helperText={errors.message?.message ?? ' '}
        />
        <CaptchaContainer>
          <ReCAPTCHA sitekey='6LeGQjQnAAAAANQ44pBHb1s8Maz-Q_g8Tu9Z2a8N' onChange={handleRecaptchaChange} />
          {errors.captchaToken?.message ? (
            <ErrorTypography variant='caption1'>{errors.captchaToken.message}</ErrorTypography>
          ) : null}
        </CaptchaContainer>
      </FormContainer>
    </Dialog>
  );
};

MarketplaceContactForm.propTypes = {
  detailId: string.isRequired,
  title: string,
  onResolve: func,
};

export default MarketplaceContactForm;
